@import '@taiga-ui/core/styles/taiga-ui-local.less';

[tuiAppearance][data-appearance='glass'] {
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
    backdrop-filter: blur(1rem);

    --tui-border-focus: rgba(255, 255, 255, 0.64);

    .appearance-hover({
        background: rgba(0, 0, 0, 0.48);
    });

    .appearance-active({
        background: rgba(0, 0, 0, 0.6);
    });
}

[tuiTheme='dark'] [tuiAppearance][data-appearance='glass'],
[tuiTheme='dark'][tuiAppearance][data-appearance='glass'] {
    background: rgba(255, 255, 255, 0.3);

    .appearance-hover({
        background: rgba(255, 255, 255, 0.4);
    });

    .appearance-active({
        background: rgba(255, 255, 255, 0.5);
    });
}
