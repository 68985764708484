@import '@taiga-ui/core/styles/taiga-ui-local.less';

[tuiAppearance][data-appearance='accent'] {
    background: var(--tui-background-accent-2);
    color: var(--tui-text-primary-on-accent-2);

    .appearance-hover({
        background: var(--tui-background-accent-2-hover);
    });

    .appearance-active({
        background: var(--tui-background-accent-2-pressed);
    });
}
