@import '@taiga-ui/core/styles/taiga-ui-local.less';

[tuiAppearance][data-appearance='error'],
[tuiAppearance][data-appearance='success'],
[tuiAppearance][data-appearance='warning'],
[tuiAppearance][data-appearance='info'],
[tuiAppearance][data-appearance='neutral'] {
    background: var(--t-bg);

    &:checked {
        color: #fff;
    }
}

[tuiAppearance][data-appearance='error'] {
    --t-bg: var(--tui-status-negative-pale);

    &::before,
    &::after {
        color: var(--tui-status-negative);
    }

    .appearance-hover({
        --t-bg: var(--tui-status-negative-pale-hover);
    });

    .appearance-active({
        --t-bg: var(--tui-status-negative-pale-hover);
    });

    &:checked {
        background: var(--tui-status-negative);
    }
}

[tuiAppearance][data-appearance='success'] {
    --t-bg: var(--tui-status-positive-pale);

    &::before,
    &::after {
        color: var(--tui-status-positive);
    }

    .appearance-hover({
        --t-bg: var(--tui-status-positive-pale-hover);
    });

    .appearance-active({
        --t-bg: var(--tui-status-positive-pale-hover);
    });

    &:checked {
        background: var(--tui-status-positive);
    }
}

[tuiAppearance][data-appearance='warning'] {
    --t-bg: var(--tui-status-warning-pale);

    &::before,
    &::after {
        color: var(--tui-status-warning);
    }

    .appearance-hover({
        --t-bg: var(--tui-status-warning-pale-hover);
    });

    .appearance-active({
        --t-bg: var(--tui-status-warning-pale-hover);
    });

    &:checked {
        background: var(--tui-status-warning);
    }
}

[tuiAppearance][data-appearance='info'] {
    --t-bg: var(--tui-status-info-pale);

    &::before,
    &::after {
        color: var(--tui-status-info);
    }

    .appearance-hover({
        --t-bg: var(--tui-status-info-pale-hover);
    });

    .appearance-active({
        --t-bg: var(--tui-status-info-pale-hover);
    });

    &:checked {
        background: var(--tui-status-info);
    }
}

[tuiAppearance][data-appearance='neutral'] {
    --t-bg: var(--tui-background-neutral-1);

    &::before,
    &::after {
        color: var(--tui-status-neutral);
    }

    .appearance-hover({
        --t-bg: var(--tui-background-neutral-1-hover);
    });

    .appearance-active({
        --t-bg: var(--tui-background-neutral-1-pressed);
    });

    &:checked {
        background: var(--tui-status-neutral);
    }
}
