@font-fallback: -apple-system, 'BlinkMacSystemFont', system-ui, 'Roboto', 'Segoe UI', 'Helvetica Neue', sans-serif;

&:root {
  // Fonts
  --tui-font-heading: 'Rubik', @font-fallback;
  --tui-font-text: 'Rubik', @font-fallback;
  // Heading
  --tui-font-heading-1: 500 32px/40px var(--tui-font-heading);
  --tui-font-heading-2: 500 24px/28px var(--tui-font-heading);
  --tui-font-heading-3: 500 16px/22px var(--tui-font-heading);
  --tui-font-heading-4: 500 1.75rem/2rem var(--tui-font-heading);
  --tui-font-heading-5: 500 1.5rem/1.75rem var(--tui-font-heading);
  --tui-font-heading-6: 500 1.25rem/1.5rem var(--tui-font-heading);
  // Body
  --tui-font-text-xl: normal 1.1875rem/1.75rem var(--tui-font-text);
  --tui-font-text-l: normal 1.0625rem/1.75rem var(--tui-font-text);
  --tui-font-text-m: normal 14px/24px var(--tui-font-text);
  --tui-font-text-s: normal 12px/18px var(--tui-font-text);
  --tui-font-text-xs: normal 0.6875rem/1rem var(--tui-font-text);
  // Reduced
  --tui-font-text-ui-l: normal 1.0625rem/1.5rem var(--tui-font-text);
  --tui-font-text-ui-m: normal 1rem/1.25rem var(--tui-font-text);
  --tui-font-text-ui-s: normal 0.8125rem/1rem var(--tui-font-text);
  --tui-font-text-ui-xs: normal 0.6875rem/0.8125rem var(--tui-font-text);
  // Radii
  --tui-radius-xs: 0.25rem;
  --tui-radius-s: 0.5rem;
  --tui-radius-m: 0.625rem;
  --tui-radius-l: 0.75rem;
  --tui-radius-xl: 1.5rem;
  // Sizes
  --tui-height-xs: 1.5rem;
  --tui-height-s: 2rem;
  --tui-height-m: 2.75rem;
  --tui-height-l: 3.5rem;
  // Input padding
  --tui-padding-s: 0.625rem;
  --tui-padding-m: 0.75rem;
  --tui-padding-l: 1rem;
  // Misc
  --tui-duration: 0.3s;
  --tui-disabled-opacity: 0.56;
}
