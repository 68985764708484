@import '@taiga-ui/core/styles/taiga-ui-local.less';

[tuiAppearance][data-appearance='opposite'] {
    --tui-border-focus: rgba(255, 255, 255, 0.64);

    background: var(--tui-background-accent-opposite);
    color: var(--tui-background-base);

    .appearance-hover({
        background: var(--tui-background-accent-opposite-hover);
    });

    .appearance-active({
        background: var(--tui-background-accent-opposite-pressed);
    });
}

[tuiTheme='dark'] [tuiAppearance][data-appearance='opposite'],
[tuiTheme='dark'][tuiAppearance][data-appearance='opposite'] {
    --tui-border-focus: rgba(51, 51, 51, 0.48);
}
