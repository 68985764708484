@import '@taiga-ui/core/styles/taiga-ui-local.less';

[tuiAppearance][data-appearance='outline'],
[tuiAppearance][data-appearance='whiteblock'] {
    --t-bs: var(--tui-border-normal);

    color: var(--tui-text-action);
    box-shadow: inset 0 0 0 1px var(--t-bs);

    &:checked,
    &[data-mode='checked'] {
        --t-bs: var(--tui-background-accent-1);

        box-shadow: inset 0 0 0 2px var(--t-bs);

        .appearance-hover({
            --t-bs: var(--tui-background-accent-1-hover);
        });

        .appearance-active({
            --t-bs: var(--tui-background-accent-1-pressed);
        });
    }

    &:invalid {
        box-shadow: inset 0 0 0 1px var(--tui-status-negative-pale-hover);
    }

    &:checked:invalid,
    &[data-mode='checked']:invalid {
        box-shadow: inset 0 0 0 2px var(--tui-status-negative);
    }

    .appearance-hover({
        background: var(--tui-background-neutral-1);
        cursor: pointer;
        --t-bs: var(--tui-border-hover);
    });

    .appearance-active({
        background: var(--tui-background-neutral-1);
        --t-bs: var(--tui-border-hover);
    });
}

[tuiAppearance][data-appearance='whiteblock'] {
    color: var(--tui-text-primary);
}
