@import '@taiga-ui/core/styles/taiga-ui-local.less';

[tuiAppearance][data-appearance='primary'] {
    --t-bg: var(--tui-background-accent-1);

    background: var(--t-bg);
    color: var(--tui-text-primary-on-accent-1);

    &:invalid {
        background: var(--tui-status-negative);
    }

    .appearance-hover({
        --t-bg: var(--tui-background-accent-1-hover);
    });

    .appearance-active({
        --t-bg: var(--tui-background-accent-1-pressed);
    });
}
