@import '@taiga-ui/core/styles/taiga-ui-local.less';

[tuiAppearance][data-appearance='icon'] {
    color: var(--tui-text-tertiary);

    .appearance-hover({
        color: var(--tui-text-secondary);
    });

    .appearance-active({
        color: var(--tui-text-primary);
    });
}

// Icons with the directive
[tuiAppearance][data-appearance='whiteblock'],
[tuiAppearance][data-appearance='floating'] {
    &::before,
    &::after {
        .transition(color);

        color: var(--tui-text-tertiary);
    }

    .appearance-hover({
        &:before,
        &:after {
            color: var(--tui-text-secondary);
        }
    });

    .appearance-active({
        &:before,
        &:after {
            color: var(--tui-text-primary);
        }
    });
}
