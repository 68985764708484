[tuiAppearance][data-appearance='link'] {
    color: var(--tui-text-action);

    .appearance-hover({
        color: var(--tui-text-action-hover);
    });

    .appearance-active({
        color: var(--tui-text-action-hover);
    });
}
